import api from './request';
import appStore from '../store/index';

// 初始化
/**
 * 统一处理错误
 * parameter Object
 * parameter.SKIP_MESSAGE    Boolean
 *   false-默认 统一处理错误，message弹出提示
 *   true-跳过提示
 * parameter.SKIP_REDIRECT    Boolean
 *   false-默认 统一错误路由
 *   true-跳过错误路由
 * parameter.LOAD    Object
 * parameter.LOAD.loading    Boolean
 *   组件上的loading值
 *
 */

// 自定义配置方法
//  before    请求发起前
api.before = (functionName, parameter = {}) => {
  if (typeof parameter.WAITING_PROMISE === 'undefined') {
    parameter.WAITING_PROMISE = true;
  }

  // 带上vuex的认证参数，暂时用于服务器端较验
  // if (appStore.state.user && appStore.state.user.info) {
  //   parameter.auth_user_id = appStore.state.user.info.id;
  // }
  // if (appStore.state.user && appStore.state.user.role) {
  //   parameter.auth_role_code = appStore.state.user.role.code;
  // }
  // 控制loading
  if (parameter.LOAD && typeof parameter.LOAD.loading === 'boolean') {
    parameter.LOAD.loading = true;
  } else if (!parameter.SKIP_LOAD) {
    try {
      appStore.commit('setDefaultLoading', { loading: true });
    } catch (error) {
      console.warn('vuex-未配置setDefaultLoading mutations--', error);
    }
  }

  // 修改HOST地址（上线时请注释掉！！！）
  if(!parameter.HOST && window.location.href.includes('http://127.0.0.1')){
     // parameter.HOST = "https://renykang.picp.net";
     // console.info( parameter.HOST);
  }
};

// success   请求成功后 后台返回code==200
api.success = parameter => {
  if (parameter.LOAD && typeof parameter.LOAD.loading === 'boolean') {
    parameter.LOAD.loading = false;
  } else if (!parameter.SKIP_LOAD) {
    try {
      appStore.commit('setDefaultLoading', { loading: false });
    } catch (error) {
      console.warn('vuex-未配置setDefaultLoading mutations--', error);
    }
  }
};

api.cancel = (parameter, cancelError) => {
  console.warn('--> api请求被取消', cancelError);
  if (parameter.LOAD && typeof parameter.LOAD.loading === 'boolean') {
    parameter.LOAD.loading = false;
  } else if (!parameter.SKIP_LOAD) {
    try {
      appStore.commit('setDefaultLoading', { loading: false });
    } catch (error) {
      console.warn('vuex-未配置setDefaultLoading mutations--', error);
    }
  }
};

// fail      请求失败后 后台返回code !== 200
api.fail = (parameter, newError) => {
  console.log('[-> ajax.js] ::back:: [api.fail]', parameter, newError);
  if (parameter.LOAD && typeof parameter.LOAD.loading === 'boolean') {
    parameter.LOAD.loading = false;
  } else if (!parameter.SKIP_LOAD) {
    try {
      appStore.commit('setDefaultLoading', { loading: false });
    } catch (error) {
      console.warn('vuex-未配置setDefaultLoading mutations--', newError);
    }
  }

  // 统一提示错误信息
  try {
    if (parameter.SKIP_MESSAGE || parameter.CATCH_ERROR) {
      // return;
    } else {
      appStore.commit('ajaxErrorMessage', newError);
    }
  } catch (error) {
    console.warn('vuex-未配置ajaxErrorMessage mutations--', newError);
  }

  // 统一处理重定向
  try {
    // 跳过重定向或者手动捕获错误时 不需要重定向
    if (parameter.SKIP_REDIRECT || parameter.CATCH_ERROR) {
      // return;
    } else {
      appStore.commit('ajaxErrorRedirect', newError);
    }
  } catch (error) {
    console.warn('vuex-未 配 置ajaxErrorRedirect mutations--', newError);
  }
};

export default api;
