import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/home'
  },
  {
    // 首页
    path: '/home',
    component: () => import('./view/home.vue')
  },
  {
    // 首页
    path: '/login',
    component: () => import('./view/login.vue')
  },
  {
    // 分类列表
    path: '/categoryList',
    component: () => import('./view/categoryList.vue')
  },
  {
    // category
    path: '/category',
    component: () => import('./view/category.vue')
  },
  {
    // 健康详情页
    path: '/goodsInfo',
    component: () => import('./view/goodsDetail.vue')
  },
  {
    // 显示图片
    path: '/showPic',
    component: () => import('./view/showPic.vue')
  },
  {
    // 我的
    path: '/me',
    component: () => import('./view/me.vue')
  },
  {
    // 我的预约
    path: '/me/list',
    component: () => import('./view/meList.vue')
  },
  {
    // 更多
    path: '/more',
    component: () => import('./view/more.vue')
  },
  {
    // 子分类
    path: '/subCategory',
    component: () => import('./view/subCategoryList.vue')
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  mode: 'history',
  base: 'page',
  routes
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

router.afterEach(() => {
  // 让页面回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

export {
  router
};
